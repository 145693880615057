import styled from 'styled-components'
import { useLoading } from '../hooks/useLoading'

export const LoadingSpinner = () => {
  const [loading] = useLoading()

  return (
    <LoadingOverlay visible={loading}>
      <LoadingGif src="/images/loading.gif" alt="loading" />
    </LoadingOverlay>
  )
}

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
  pointer-events: auto;

  visibility: ${props => props.visible ? `visible` : `hidden`};
  opacity: ${props => props.visible ? 1 : 0};
  transition: visibility 1s, opacity 1s linear;
`

const LoadingGif = styled.img`
  position: relative;
`
