import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/analytics'
import { getStorage } from 'firebase/storage'
import firebase from 'firebase/compat/app'
import 'firebase/compat/functions'
import { firebaseCreds } from '../config'
import { getFunctions } from 'firebase/functions'
const region = `europe-west3`

const app = firebase.initializeApp(firebaseCreds)

const db = firebase.firestore()
const auth = firebase.auth()
const analytics = firebase.analytics()
const storage = getStorage(app)
const functions = getFunctions(app,region)

export { firebase,auth,db,storage,functions,analytics }
