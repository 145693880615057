import { auth,db } from '../../services/firebase'

export const getAccountType = async() => {
  const { currentUser } = auth

  if (!currentUser || !currentUser.uid)
    return null


  try {
    const userDocument = await db.collection(`users`).doc(currentUser.uid).get()

    if (!userDocument.exists)
      return null


    const { type: accountType,active,forceEmailVerified } = userDocument.data()
    return { accountType,active,forceEmailVerified }
  } catch (error) {
    console.error(`Failed to fetch account type: ${error}`)
    return null
  }
}

export const getAllHotels = async() => {
  // eslint-disable-next-line
  if (auth?.currentUser?.uid != undefined) {
    const getUserData = await db
      .collection(`users`)
      .doc(auth?.currentUser?.uid)
      .get()
    const getGroupId = getUserData.data()?.currentSelectedGroup.id
    const getGroupIdData = await db.collection(`users`).doc(getGroupId).get()

    const userHotels = getGroupIdData.data()?.hotels
    return userHotels
  }
}

