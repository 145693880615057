import { Heading } from '../../v2-ui-components/Heading'
import SideContent from './SideContent'
import styled from 'styled-components'
import { Description } from '../../v2-ui-components/Description'
import logo from '../../images/logo.svg'
import { useHistory } from 'react-router-dom'

export const Success = () => {
  const history = useHistory()

  return (
    <Container>
      <SignupSection>
        <SuccessContainer>
          <Logo src={logo} alt="Logo" />
          <Heading>
            Thank you for signing up! Please check your email for a verification link.
          </Heading>
          <Description>
            Once verified, you will be able to log in to your account.
          </Description>
          <p
            onClick={() => {
              history.push(`/SignInPage`)
            }}
            style={{
              fontFamily: `DM Sans`,
              fontStyle: `normal`,
              fontWeight: `700`,
              fontSize: `14px`,
              lineHeight: `21px`,
              color: `#14358A`,
              cursor: `pointer`,
            }}>
            Click here to Log in
          </p>
        </SuccessContainer>
      </SignupSection>
      <SideContent />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  flex-wrap: 'wrap';
`

const SignupSection = styled.div`
  display: flex;
  flex: 1;
  min-height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  flex-wrap: 'wrap';
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const SuccessContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10%;
    margin-top: 2em;
    gap: 1em;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
`

const Logo = styled.img`
  width: 10em;
  margin-bottom: 2em;
`
