import styled from 'styled-components'

export const Button = ({
  children,disabled,onClick,active,inverted,size = `medium`,...props
}) => {
  return (
    <StyledButton
      size={size}
      disabled={disabled}
      onClick={onClick}
      inverted={inverted ? `true` : `false`}
      {...props}>
      {children}
    </StyledButton>
  )
}

const StyledButton = styled.button`
  background-color: ${props => (props.inverted === `true` ? `transparent` : `var(--primary)`)};
  border: ${props => (props.inverted === `true` ? `1px solid var(--primary)` : `none`)};
  border-radius: 4px;
  color: ${props => (props.inverted === `true` ? `var(--primary)` : `white`)};
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 16px;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in-out;
  justify-content: center;
  align-items: center;
  font-size: ${props => {
    if (props.size === `small`) return `0.8em`
    if (props.size === `medium`) return `1em`
    if (props.size === `large`) return `1.2em`
    if (props.size === `extra-large`) return `1.5em`
    if (props.size === `extra-extra-large`) return `2em`
    if (props.size === `extra-small`) return `0.5em`
    if (props.size === `extra-extra-small`) return `0.4em`
  }
};

padding: ${props => {
    if (props.size === `small`) return `0.5em 1em`
    if (props.size === `medium`) return `8px 16px`
    if (props.size === `large`) return `1em 2em`
    if (props.size === `extra-large`) return `1.5em 3em`
    if (props.size === `extra-extra-large`) return `2em 4em`
    if (props.size === `extra-small`) return `0.3em 0.5em`
    if (props.size === `extra-extra-small`) return `0.2em 0.4em`
  }
};

  &:disabled {
    background-color: #e0e0e0;
    color: #9e9e9e;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  & > svg {
    font-size: 1.5em;
  }

  ${props => props.css}
`
