import styled from 'styled-components'

export default function SideContent() {
  return (
    <Contents>
      <ContentsContainer>
        <IllustrationImage src="/images/Bitmap.png" alt="Bitmap" />
        <IllustrationTexts>
          <BoldIllustrationText>
            Are you ready to take your business to the next level?
          </BoldIllustrationText>
          <Slider src="/images/Slider.png" alt="Slider" />
          <SliderText>
            BI & Data analytics
          </SliderText>
          <SliderSubText>
            Engine
          </SliderSubText>
        </IllustrationTexts>
      </ContentsContainer>
    </Contents>
  )
}

const Slider = styled.img`
margin-top: 2em;
margin-bottom: 2em;
`

const SliderText = styled.div`
font-family: 'DM Sans';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 24px;
text-align: center;
color: #FFFFFF;
margin-top: 1em;
`

const SliderSubText = styled.p`
font-family: 'DM Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
text-align: center;
color: rgba(255, 255, 255, 0.5);
`

const Contents = styled.div`
  display: flex;
  flex: 1;
  width: 100vw;
  background-image: url('/images/signup-gradient.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-wrap: 'wrap';
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`

const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const IllustrationImage = styled.img`
  max-width: 35em;
  width: 100%;
`

const BoldIllustrationText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
`

const IllustrationTexts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10%;
`
