const { REACT_APP_ENV } = process.env

const firebaseProdCreds = {
  apiKey: process.env.REACT_APP_PROD_API_KEY,
  authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PROD_APP_ID,
  measurementId: process.env.REACT_APP_PROD_MEASUREMENT_ID,
}

const firebaseDevCreds = {
  apiKey: process.env.REACT_APP_DEV_API_KEY,
  authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_DEV_APP_ID,
}

export const firebaseCreds = REACT_APP_ENV === `development` ? firebaseDevCreds : firebaseProdCreds
// export const firebaseCreds = firebaseProdCreds

export const IS_FIRESTORE_DEV = firebaseCreds.projectId !== `brainy-v2`

export const bigqueryRegion = process.env.REACT_APP_BIGQUERY_REGION

export const PMSList = [
  { value: `bbi`,label: `Brainy BI`,pmsId: -2 },
  { value: `bmi`,label: `Brainy Market Insight`,pmsId: -1 },
  { value: `octorate`,label: `Octorate`,pmsId: 1 },
  { value: `mews`,label: `Mews`,pmsId: 2 },
  { value: `krossbooking`,label: `Krossbooking`,pmsId: 3 },
  { value: `beddy`,label: `Beddy`,pmsId: 4 },
  { value: `scidoo`,label: `Scidoo`,pmsId: 5 },
  { value: `cinquestelle`,label: `5stelle`,pmsId: 6 },
  { value: `passepartout`,label: `Passepartout`,pmsId: 7 },
  { value: `ericsoft`,label: `Ericsoft`,pmsId: 8 },
  { value: `slope`,label: `Slope`,pmsId: 9 },
  { value: `bookingdesigner`,label: `Booking Designer`,pmsId: 10 },
  { value: `mycomp`,label: `Mycomp/Myguestcare`,pmsId: 11 },
]

export const USER_ROLES = {
  SUPER_ADMIN: `super_admin`,
  SALES_ADMIN: `sales_admin`,
  GROUP: `group`,
}

export const PRODUCT_TYPES = {
  PROPERTY: `property`,
  API: `api`,
  BMI_PROPERTY: `bmi-property`,
  BI: `BI`,
}

export const TUTORIALS = {
  RMS_CALENDAR: `6jWJHQARc7`,
  BMI_CALENDAR: `Lf96XvgRR4`,
  DAILY_IMP_STRATERGY: `zKsh3PMQdt`,
}

export const TRIAL_DAYS = 7

export const SERVER_URL = process.env.REACT_APP_SERVER_URL

export const OPENAI_API_TOKEN = process.env.REACT_APP_OPENAI_API_TOKEN

export const DEFAULT_LANGUAGE = `en`
export const ADMIN_DEFAULT_LANGUAGE = `en`

export const APP_VERSION = `2.12.0`

console.log({ REACT_APP_ENV })
