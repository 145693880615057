import {
  background,cardColor,customGreen,appBarText,customRed,dark,darker,darkgray,description,error,light,lightest,lightgray,navBar,primary,secondary,text,v2InputBorder,v2InputPlaceholder,v2Primary,v2Description,v2ButtonText,v2Dark,
  eddieColor,
} from '../utils/colors'

export const useColors = () => {
  const { style } = document.querySelector(`:root`)
  style.setProperty(`--lightgray`,lightgray)
  style.setProperty(`--darkgray`,darkgray)
  style.setProperty(`--primary`,primary)
  style.setProperty(`--secondary`,secondary)
  style.setProperty(`--customRed`,customRed)
  style.setProperty(`--error`,error)
  style.setProperty(`--customGreen`,customGreen)
  style.setProperty(`--light`,light)
  style.setProperty(`--lightest`,lightest)
  style.setProperty(`--dark`,dark)
  style.setProperty(`--darker`,darker)
  style.setProperty(`--cardColor`,cardColor)
  style.setProperty(`--text`,text)
  style.setProperty(`--description`,description)
  style.setProperty(`--background`,background)
  style.setProperty(`--navBar`,navBar)
  style.setProperty(`--appBarText`,appBarText)
  style.setProperty(`--eddieColor`,eddieColor)
  // v2 colors
  style.setProperty(`--v2InputBorder`,v2InputBorder)
  style.setProperty(`--v2InputPlaceholder`,v2InputPlaceholder)
  style.setProperty(`--v2Primary`,v2Primary)
  style.setProperty(`--v2Dark`,v2Dark)
  style.setProperty(`--v2Description`,v2Description)
  style.setProperty(`--v2ButtonText`,v2ButtonText)
}
