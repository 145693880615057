import { appBarText,background,cardColor,description,navBar,text } from '../utils/colors'

export const useLightMode = () => {
  const root = document.documentElement
  root.style.setProperty(`--cardColor`,cardColor)
  root.style.setProperty(`--text`,text)
  root.style.setProperty(`--description`,description)
  root.style.setProperty(`--background`,background)
  root.style.setProperty(`--navBar`,navBar)
  root.style.setProperty(`--appBarText`,appBarText)
  document.body.style.backgroundColor = `white`
}
