import './App.css'
import React,{ Suspense,lazy } from 'react'
import '@fontsource/plus-jakarta-sans'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-calendar/dist/Calendar.css'
import 'react-toastify/dist/ReactToastify.css'
import './i18n'
import { Route,BrowserRouter as Router,Switch } from 'react-router-dom'
import { AuthProvider } from './components/Context/AuthUserContext'
import { ToastContainer } from 'react-toastify'
import { useColors } from './hooks/useColors'
import { LoadingSpinner } from './ui-components/LoadingSpinner'
import styled from 'styled-components'
import { Success } from './components/SignupComponents/Success'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from './components/Error/ErrorFallback'
import { lazyRetry } from './utils/lazyRetry'

const AutoSignIn = lazy(() => lazyRetry(() => import(`./components/auth/autoSignIn`),`AutoSignIn`))
const Dashboard = lazy(() => lazyRetry(() => import(`./components/Dashboard/Dashboard`),`Dashboard`))
const SalesDashboard = lazy(() => lazyRetry(() => import(`./components/SalesDashboard/SalesDashboard`),`SalesDashboard`))
const ProtectedRoute = lazy(() => lazyRetry(() => import(`./components/ProtectedRoutes/ProtectedRoutes`),`ProtectedRoute`))
const SignInPage = lazy(() => lazyRetry(() => import(`./components/SignIn/SignInPage`),`SignInPage`))
const SignUp = lazy(() => lazyRetry(() => import(`./components/SignupComponents/SignUp`),`SignUp`))
const NoProperty = lazy(() => lazyRetry(() => import(`./components/NotFound/NoProperty`),`NoProperty`))
const WrongRoute = lazy(() => lazyRetry(() => import(`./components/NotFound/404`),`WrongRoute`))
const PmsDashboard = lazy(() => lazyRetry(() => import(`./components/PmsDashboard/PmsDashboard`),`PmsDashboard`))
const PaymentStatus = lazy(() => lazyRetry(() => import(`./components/SalesDashboard/Billing/PaymentStatus`),`PaymentStatus`))

function App() {
  useColors()

  return (
    <AuthProvider>
      <LoadingSpinner />
      <ToastContainer />
      <div>
        <Router>
          <Suspense
            fallback={
              <LoadingGif src="/images/loading.gif" alt="loading" />
            }>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Switch>
                <ProtectedRoute exact path="/" component={SignInPage} />
                <Route path="/SignInPage" component={SignInPage} />
                <Route path="/SignUp" component={SignUp} />
                <Route path="/Success" component={Success} />
                <ProtectedRoute path="/dashboard" component={Dashboard} />
                <ProtectedRoute path="/pmsdashboard" component={PmsDashboard} />
                <ProtectedRoute path="/salesdashboard" component={SalesDashboard} />
                <Route path="/autosignin" component={AutoSignIn} />
                <Route path="/noproperty" component={NoProperty} />
                <Route path="/paymentstatus" component={PaymentStatus} />
                <Route path="*" component={WrongRoute} />
              </Switch>
            </ErrorBoundary>
          </Suspense>
        </Router>
      </div>
    </AuthProvider>
  )
}

export default App

const LoadingGif = styled.img`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
