import React,{ useContext,useEffect,useState } from 'react'
import { toast } from 'react-toastify'
import { auth } from '../../services/firebase'
import { getAccountType } from '../utils'
import { useLightMode } from '../../hooks/useLightMode'
import { useHistory } from 'react-router-dom'
import { primary } from '../../utils/colors'
import { sendVerificationEmailIfRequired } from '../auth/sendVerificationEmailIfRequired'
import { setAuthLocalStorageItems } from '../auth/setAuthLocalStorageItems'
import { USER_ROLES } from '../../config'
import { helpHero } from '../../services/helphero'
const AuthContext = React.createContext()

export const useAuth = () => useContext(AuthContext)

const BYPASS_2FA = [
  USER_ROLES.SUPER_ADMIN,
  // USER_ROLES.SALES_ADMIN,
]

export const AuthProvider = ({ children }) => {
  const [currentUser,setCurrentUser] = useState()
  const [loading,setLoading] = useState(true)
  const history = useHistory()

  const signin = async(email,password) => {
    try {
      await auth.signInWithEmailAndPassword(email,password)
      const { currentUser } = auth

      const accountData = await getAccountType()
      if (!accountData?.accountType) {
        toast.error(`You are not authorized to login here, Please contact customer support for missing user role.`)
        auth.signOut()
        return
      }

      const { accountType,active,forceEmailVerified } = accountData
      if (!BYPASS_2FA.includes(accountType) && process.env.REACT_APP_ENV === `production` && !forceEmailVerified) {
        await sendVerificationEmailIfRequired(currentUser,email)
        if (!currentUser?.emailVerified) {
          auth.signOut()
          return null
        }
      }

      setAuthLocalStorageItems(accountType,active,await currentUser.getIdToken(true))
      return { accountType,active }
    } catch (err) {
      switch (err.code) {
        case `auth/Invalid-email`:
        case `auth/user-disabled`:
        case `auth/user-not-found`:
          toast.error(`Invalid email address`,`Try again!`,`error`)
          break
        case `auth/wrong-password`:
          toast.error(`Incorrect password`,`Try again!`,`error`)
          break
        default:
          toast.error(err.message,`Try again!`,`error`)
      }
      return null
    }
  }


  function logOut() {
    useLightMode()
    document.documentElement.style.setProperty(`--primary`,primary)

    const { email } = auth.currentUser

    auth.signOut().then(() => {
      setCurrentUser(null)
      setLoading(true)
      localStorage.removeItem(`token`)
      localStorage.removeItem(`accountType`)
      localStorage.removeItem(`hotelLength`)
      localStorage.removeItem(`verificationEmailSent-${email}`)
      history && history.push(`/SignInPage`)
    })
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      if (!user) {
        setLoading(false)
        return
      }

      helpHero.identify(auth.currentUser.uid,{
        email: auth.currentUser.email,
        name: auth.currentUser.displayName,
        role: currentUser?.type,
        environment: process.env.REACT_APP_ENV,
      })

      setLoading(false)
    })
    return unsubscribe
  },[currentUser])

  const value = {
    currentUser,
    signin,
    logOut,
  }
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
