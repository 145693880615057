import styled from 'styled-components'

export const Heading = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: var(--v2Primary);
`
