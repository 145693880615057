export const lightgray = `#EEEEEE`
export const darkgray = `rgb(55, 65, 81)`
export const primary = `#655DBB`
export const secondary = `rgb(255 172 50)`
export const customRed = `#B46060`
export const customGreen = `#5D9C59`
export const error = `#ff0000`
export const light = `#A084DC`
export const lightest = `#BFACE2`
export const dark = `#645CBB`
export const darker = `#4B3F8C`
export const cardColor = `white`
export const darkModeCardColor = `#444f54`
export const text = `#000000`
export const description = `#4B4B4B`
export const background = `#EEEEEE`
export const darkBackground = `#222222`
export const navBar = `rgb(255 255 255)`
export const appBarText = `rgba(112, 112, 124, 1)`
export const eddieColor = `#7abad7`

// v2 colors
export const v2InputBorder = `rgba(207, 219, 213, 0.7)`
export const v2InputPlaceholder = `#747a80`
export const v2Primary = `#14358A`
export const v2Dark = `#0f2a6b`
export const v2Description = `#747A80`
export const v2ButtonText = `#ffffff`
