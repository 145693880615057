import styled from 'styled-components'

export const Description = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--v2Description);
`
