import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from '../../ui-components/Button'
import { Card } from '../../ui-components/Card'
import { APP_VERSION } from '../../config'

export const ErrorFallback = ({ error,resetErrorBoundary }) => {
  const location = useLocation()

  return (
    <Container>
      <Card>
        <Heading>Something went wrong!</Heading>
        <Version>v{APP_VERSION}</Version>
        <RouteName>Route: {location.pathname}</RouteName>
        <Details open>
          <Summary>Show Details</Summary>
          <div>Error Message: {error.message}</div>
          <Pre>Stack Trace: {error.stack}</Pre>
        </Details>
        <Button onClick={resetErrorBoundary}>Try again</Button>
      </Card>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10%;
`

const Heading = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: #000;
  margin-bottom: 2rem;
`

const Details = styled.details`
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 2rem;
`

const Summary = styled.summary`
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 2rem;
`

const Pre = styled.pre`
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 2rem;
`

const RouteName = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 2rem;
`

const Version = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 2rem;
`
