export const lazyRetry = function(componentImport,name) {
  const retryName = `retry-${name}-lazy-refreshed`
  return new Promise((resolve,reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(retryName) || `false`,
    )
    // try to import the component
    componentImport().then(component => {
      window.sessionStorage.setItem(retryName,`false`) // success so reset the refresh
      resolve(component)
    }).catch(error => {
      if (!hasRefreshed) { // not been refreshed yet
        window.sessionStorage.setItem(retryName,`true`) // we are now going to refresh
        return window.location.reload() // refresh the page
      }
      reject(error) // Default error behaviour as already tried refresh
    })
  })
}
