import dayjs from 'dayjs'
import { toast } from 'react-toastify'

export const sendVerificationEmailIfRequired = async(currentUser,email) => {
  try {
    if (currentUser?.emailVerified) return
    const emailSentAt = localStorage.getItem(`verificationEmailSent-${email}`)
    const currentTime = dayjs().valueOf()
    const timeDifference = currentTime - (emailSentAt || 0)


    if (emailSentAt && timeDifference < 1 * 60 * 1000)
      return toast.info(`
      Your email is not verified. We have sent you a verification email at ${dayjs(Number(emailSentAt)).format(`YYYY-MM-DD HH:mm:ss`)}. 
      Please check your email for verification link and click on it to verify your email address.
      Also check the spam folder if you don't find it in your inbox.
      `)

    await currentUser.sendEmailVerification()
    toast.info(`
      Your email is not verified. We have sent you a verification email at ${dayjs().format(`YYYY-MM-DD HH:mm:ss`)}.
      Please check your email for verification link and click on it to verify your email address.
      Also check the spam folder if you don't find it in your inbox.
      `)

    return localStorage.setItem(
      `verificationEmailSent-${email}`,
      currentTime.toString(),
    )
  } catch (err) {
    toast.error(
      `Please check your email for verification link and click on it to verify your email address`,
    )
    console.log(err)
    return null
  }
}
