import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'

import en from './locales/en/translation.json'
import es from './locales/es/translation.json'
import it from './locales/it/translation.json'
import fr from './locales/fr/translation.json'
import de from './locales/de/translation.json'
import { DEFAULT_LANGUAGE } from './config'

const resources = {
  en: { translation: en },
  es: { translation: es },
  it: { translation: it },
  fr: { translation: fr },
  de: { translation: de },
}

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    resources,
    lng: DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE,
    debug: true,
    interpolation: { escapeValue: false },
  })

export default i18n
